(function(){
  var matches = window.location.href.match(/[a-z\-\_]+=([^&]*)/ig);
  var params = JSON.parse(sessionStorage.getItem("context")) || {};
  for(var match in matches){
    const qs = matches[match].split("=")
    params[qs[0]]= decodeURIComponent(qs[1]);
  }
  sessionStorage.setItem("context", JSON.stringify(params));
  return params
})()

function showLoader(){
  if($(".overlay").length > 0){
    $(".overlay").remove()
  }
  $("body").append('<div class="overlay position-fixed text-center vh-100 vw-100 align-middle">'+
  '  <div class="spinner spinner-border text-success" role="status">'+
  '    <span class="sr-only">Loading...</span>'+
  '  </div>'+
  '</div>')

  $(".spinner").css('margin-top', (parseFloat(window.innerHeight)/2) + 'px')
  $("body").css("overflow", "hidden")
}

function hideLoader(){
  if($(".overlay").length > 0){
    $(".overlay").remove()
  }
  $("body").css("overflow", "auto")
}

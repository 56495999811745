const env = 'prod';
let base = typeof(env) == 'undefined' ? 'dev-' : `${env}-`
if(typeof(env) != 'undefined' && env == 'prod'){
    base = ''
}
const BASE_URLS = {
    Dinny:{
        api: `https://${base}api.dinnyapp.com/cms`,
        no_cms: `https://${base}api.dinnyapp.com`
    },
    Standalone: {
        api: `https://${base}pim.dinnyapp.com`
    },
    Management: {
        api: `https://${base}mgt-auth.dinnyapp.com`,
        api_key_auth : typeof(env) == 'undefined' ?
            'dev-mgt-jx7ff37n2hKf7W' :
            env == 'dev' ?
                'dev-mgt-jx7ff37n2hKf7W':
            env == 'qa' ?
                'qa-mgt-WER6VEJQE8WKzc' :
            env == 'sales' ?
                'sales-mgt-4Wf5y5Djzx4hfdm' :
            env == 'prod' ?
                'prod-mgt-TV6XHLz3mHZC9W' :
                'api-key-not-found'
    }
}
const UI = {
    alert: function(message, type){
        const template = `<div class="main-alert animate__animated animate__fadeIn animate__more-faster">
            <div class="alert-${type ? type : 'error'}">${message}</div>
            <span class="alert-close" onclick="document.querySelector('.alerts-container').innerHTML = ''    "></span>
        </div>`

        let target = document.querySelector('.alerts-container')
        if(!target){
            throw new Error('Invalid message container (div.alerts-container).')
        }
        target.innerHTML = ''
        target.insertAdjacentHTML(
            'afterbegin',
            template
        )
    },
    message: function(data, callback){
        const template = `
        <div class="modal-back animate__animated animate__fadeIn animate__more-faster" id="modalPrompt" style="display: block">
          <div class="main-container" id="modalContainerPrompt">
            <div class="card-base modal-content">
              <div class="modal-body">
                <span class="modal-body-title">${data.title}</span>
                <p class="mb-2">${data.content}</p>
                <div class="modal-actions">
                  <a class="btn btn-solid-primary" href="#" id="ui-saveModal">${data.accept}</a>
                </div>
              </div>
            </div>
          </div>
        </div>`

        document.querySelector('body').insertAdjacentHTML(
            'beforeend',
            template
        )

        document.querySelector("#ui-saveModal").addEventListener('click', function(e){
            e.preventDefault()
            closeModal()
            if(callback)
                callback(true)
        })
        function closeModal(){
            let el = document.getElementById('modalPrompt')
            el.parentNode.removeChild(el)
        }
    },
    messageList: function(data, callback){
      const template = `
      <div class="modal-back animate__animated animate__fadeIn animate__more-faster" id="modalPrompt" style="display: block">
        <div class="main-container" id="modalContainerPrompt">
          <div class="card-base modal-content">
            <div class="modal-body">
              <span class="modal-body-title mb-1">${data.title}</span>
              ${data.content}
              <div class="modal-actions">
                <a class="btn btn-solid-primary" href="#" id="ui-saveModal">${data.accept}</a>
              </div>
            </div>
          </div>
        </div>
      </div>`

      document.querySelector('body').insertAdjacentHTML(
          'beforeend',
          template
      )

      document.querySelector("#ui-saveModal").addEventListener('click', function(e){
          e.preventDefault()
          closeModal()
          if(callback)
              callback(true)
      })
      function closeModal(){
          let el = document.getElementById('modalPrompt')
          el.parentNode.removeChild(el)
      }
  },
    detailTransaction: function(data, callback){
      const template = `
      <div class="modal-back animate__animated animate__fadeIn animate__more-faster" id="modalPrompt" style="display: block">
        <div class="main-container" id="modalContainerPrompt">
          <div class="card-base modal-content">
            <div class="modal-body">
              <div class="icon-close cross-modal" id="ui-saveModal"></div>
              <span class="modal-body-title">${data.title}</span>
              <p class="m-0">${data.content}</p>
            </div>
          </div>
        </div>
      </div>`

      document.querySelector('body').insertAdjacentHTML(
          'beforeend',
          template
      )

      document.querySelector("#ui-saveModal").addEventListener('click', function(e){
          e.preventDefault()
          closeModal()
          if(callback)
              callback(true)
      })
      function closeModal(){
          let el = document.getElementById('modalPrompt')
          el.parentNode.removeChild(el)
      }
  },
    confirm: function(data, callback){
        if(!callback){
            throw new Error('Invalid callback (UI.confirm( {}, () => {}))')
        }
        const template = `
        <div class="modal-back animate__animated animate__fadeIn animate__more-faster" id="modalPrompt" style="display: block">
          <div class="main-container" id="modalContainerPrompt">
            <div class="card-base modal-content">
              <div class="modal-body">
                <span class="modal-body-title">${data.title}</span>
                <p class="mb-2">${data.content}</p>
                <div class="modal-actions">
                  <a class="btn btn-outline-primary mr-2" href="#" id="ui-cancelModal">${data.cancel}</a>
                  <a class="btn btn-solid-primary" href="#" id="ui-saveModal">${data.accept}</a>
                </div>
              </div>
            </div>
          </div>
        </div>`

        document.querySelector('body').insertAdjacentHTML(
            'beforeend',
            template
        )

        document.querySelector("#ui-cancelModal").addEventListener('click', function(e){
            e.preventDefault()
            closeModal()
            callback(false)
        })
        document.querySelector("#ui-saveModal").addEventListener('click', function(e){
            e.preventDefault()
            closeModal()
            callback(true)
        })
        function closeModal(){
            let el = document.getElementById('modalPrompt')
            el.parentNode.removeChild(el)
        }
    },
    showLoader: function(){
        if(document.getElementById('loader'))
          return
        const template = `<div class="loader-back" id="loader">
            <div class="main-container">
                <lottie-player class="loader-container" src="src/img/loader/loader.json" background="transparent" speed="1" loop="" autoplay="autoplay"></lottie-player>
            </div>
        </div>`
        document.querySelector('body').insertAdjacentHTML(
            'beforeend',
            template
        )
    },
    hideLoader: function(){
        let loader = document.querySelector('#loader')
        if(loader)
          loader.parentNode.removeChild(loader)
    }
}
window.alert = UI.alert
const   addStoreButton = document.querySelector('#add-store'),
        modalMenu = document.querySelector('#modal'),
        modalContainer = document.querySelector('#modalContainer'),
        cancelModal = document.querySelector('#cancelModal'),
        saveModal = document.querySelector('#saveModal'),
        modalPause = document.querySelector('#modalPause'),
        cancelPauseModal = document.querySelector('#cancelPauseModal'),
        confirmPauseModal = document.querySelector('#confirmPauseModal');

let     arrayStoresMoro = [],
        elementsNavPanel = document.querySelectorAll('.item-menu-establishment'),
        panels = document.querySelectorAll('.container-store');


sessionStorage.removeItem('context');

document.querySelector('.sidebar-profile-user').innerText = localStorage.getItem('current_user') || '';

        let cardTemplate = {
          pim: `<div class="store-item animate__animated animate__fadeIn" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
              </div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <span class="action action-info" title="Editar nombre o imagen" data-name="__NAME__" data-image="__IMAGE__" onclick="updateEstablishment(__ID__, this);">
                <i class="icon-edit"></i>
              </span>
              <span class="action action-sync ml-1" title="Sincronizar con Dinny" onclick="__SHOW_SYNC__">
                <i class="icon-transaction"></i>
              </span>
              <a class="tag-menus ml-1" href="menus.html?store_id=__ID__&store_name=__URL__&store_image=__IMAGE__">Menús</a>
            </div>
          </div>
        </div>`,
          both: `<div class="store-item animate__animated animate__fadeIn" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
               </div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <!--<span class="action action-info" title="Editar nombre o imagen" data-name="__NAME__" data-image="__IMAGE__" onclick="updateEstablishment('__ID__', this);">
                <i class="icon-edit"></i>
              </span>-->
              <span class="action action-edit ml-1" title="Ver perfil" data-name="__NAME__" data-image="__IMAGE__" onclick="showCompleteInfo('__ID_DINNY__', this);">
                      <i class="icon-settings"></i>
                    </span>
              <span class="action action-sync ml-1" title="Sincronizar con Dinny" onclick="__SHOW_SYNC__">
                <i class="icon-transaction"></i>
              </span>
              <a class="tag-menus ml-1" href="menus.html?store_id=__ID__&store_name=__URL__&store_image=__IMAGE__">Menús</a>
            </div>
          </div>
        </div>`,
          dinny: `<div class="store-item animate__animated animate__fadeIn" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
              </div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <span class="action action-edit ml-1" title="Ver perfil" data-name="__NAME__" data-image="__IMAGE__" onclick="showCompleteInfo('__ID_DINNY__', this);">
                <i class="icon-settings"></i>
              </span>
            </div>
          </div>
        </div>`,
        pimSwitch: `<div class="store-item animate__animated animate__fadeIn" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
              </div>
              <div class="switch-disable-establishment" onclick="openPauseModal(event)"></div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <span class="action action-info" title="Editar nombre o imagen" data-name="__NAME__" data-image="__IMAGE__" onclick="updateEstablishment(__ID__, this);">
                <i class="icon-edit"></i>
              </span>
              <span class="action action-sync ml-1" title="Sincronizar con Dinny" onclick="__SHOW_SYNC__">
                <i class="icon-transaction"></i>
              </span>
              <a class="tag-menus ml-1" href="menus.html?store_id=__ID__&store_name=__URL__&store_image=__IMAGE__">Menús</a>
            </div>
          </div>
        </div>`,
          bothSwitch: `<div class="store-item animate__animated animate__fadeIn" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
               </div>
               <div class="switch-disable-establishment" onclick="openPauseModal(event)"></div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <!--<span class="action action-info" title="Editar nombre o imagen" data-name="__NAME__" data-image="__IMAGE__" onclick="updateEstablishment('__ID__', this);">
                <i class="icon-edit"></i>
              </span>-->
              <span class="action action-edit ml-1" title="Ver perfil" data-name="__NAME__" data-image="__IMAGE__" onclick="showCompleteInfo('__ID_DINNY__', this);">
                      <i class="icon-settings"></i>
                    </span>
              <span class="action action-sync ml-1" title="Sincronizar con Dinny" onclick="__SHOW_SYNC__">
                <i class="icon-transaction"></i>
              </span>
              <a class="tag-menus ml-1" href="menus.html?store_id=__ID__&store_name=__URL__&store_image=__IMAGE__">Menús</a>
            </div>
          </div>
        </div>`,
          dinnySwitch: `<div class="store-item animate__animated animate__fadeIn" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
              </div>
              <div class="switch-disable-establishment" onclick="openPauseModal(event)"></div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <span class="action action-edit ml-1" title="Ver perfil" data-name="__NAME__" data-image="__IMAGE__" onclick="showCompleteInfo('__ID_DINNY__', this);">
                <i class="icon-settings"></i>
              </span>
            </div>
          </div>
        </div>`,
        pimSwitchPaused: `<div class="store-item animate__animated animate__fadeIn establishment-paused" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
              </div>
              <div class="switch-disable-establishment" onclick="openPauseModal(event)"></div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <span class="action action-info" title="Editar nombre o imagen" data-name="__NAME__" data-image="__IMAGE__" onclick="updateEstablishment(__ID__, this);">
                <i class="icon-edit"></i>
              </span>
              <span class="action action-sync ml-1" title="Sincronizar con Dinny" onclick="__SHOW_SYNC__">
                <i class="icon-transaction"></i>
              </span>
              <a class="tag-menus ml-1" href="menus.html?store_id=__ID__&store_name=__URL__&store_image=__IMAGE__">Menús</a>
            </div>
          </div>
        </div>`,
          bothSwitchPaused: `<div class="store-item animate__animated animate__fadeIn establishment-paused" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
               </div>
               <div class="switch-disable-establishment" onclick="openPauseModal(event)"></div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <!--<span class="action action-info" title="Editar nombre o imagen" data-name="__NAME__" data-image="__IMAGE__" onclick="updateEstablishment('__ID__', this);">
                <i class="icon-edit"></i>
              </span>-->
              <span class="action action-edit ml-1" title="Ver perfil" data-name="__NAME__" data-image="__IMAGE__" onclick="showCompleteInfo('__ID_DINNY__', this);">
                      <i class="icon-settings"></i>
                    </span>
              <span class="action action-sync ml-1" title="Sincronizar con Dinny" onclick="__SHOW_SYNC__">
                <i class="icon-transaction"></i>
              </span>
              <a class="tag-menus ml-1" href="menus.html?store_id=__ID__&store_name=__URL__&store_image=__IMAGE__">Menús</a>
            </div>
          </div>
        </div>`,
          dinnySwitchPaused: `<div class="store-item animate__animated animate__fadeIn establishment-paused" data-id="__DATAID__">
          <div class="store-card-release store-pim">
            <div class="store-card-release__image">
              <div class="img-container">
                <img src="__IMAGE__" alt="__NAME__">
              </div>
              <div class="switch-disable-establishment" onclick="openPauseModal(event)"></div>
            </div>
            <div class="store-card-release__name">
              <p>___NAME___</p>
            </div>
            <div class="store-card-release__actions">
              <span class="action action-edit ml-1" title="Ver perfil" data-name="__NAME__" data-image="__IMAGE__" onclick="showCompleteInfo('__ID_DINNY__', this);">
                <i class="icon-settings"></i>
              </span>
            </div>
          </div>
        </div>`
        }

window.addEventListener("load", loadPage)

function loadPage(){
  loadStores()
};

var url_picture = null;
function previewPicture(e){
  url_picture = null;

  let outPut = document.querySelector('.menu-product-img');
  outPut.innerHTML = "";
  let outPutImage = document.createElement("img");
  outPutImage.src = URL.createObjectURL(e.target.files[0]);
  outPutImage.onload = function() {
      URL.revokeObjectURL(outPutImage.src) // free memory

      if(this.width >= 500 && this.height >= 500){
        if(e.target.files[0].size < 4096000)
          outPut.appendChild(outPutImage);
        else{
          UI.message({title: 'Error', content: 'Por favor, seleccione una imagen de menos de 4MB.', accept: 'Aceptar'});
          e.target.value = "";
        }
      }
      else{
        UI.message({title: 'Error', content: 'Por favor, seleccione una imagen de al menos 500x500 pixeles.', accept: 'Aceptar'});
        e.target.value = "";
      }
  }
}

function loadStores() {
  if ((localStorage.getItem('current_user') || "").toLowerCase() != "admin@elmoro.com" && (localStorage.getItem('user_type') || "") == "1")
    document.getElementById('add-store').style.display = "flex";

  var containerDinny = document.getElementById('container-dinny')
  var containerMoro = document.getElementById('container-moro')
  var containerAll = document.getElementById('container-all')
  var containerInactive = document.getElementById('container-inactive')

  containerDinny.innerHTML = '';
  containerMoro.innerHTML = '';
  containerAll.innerHTML = '';
  containerInactive.innerHTML = '';

  UI.showLoader();

  fetch( BASE_URLS.Standalone.api + '/establishments/?req_id=' + new Date().getTime(),{
      credentials: 'include'
    }
  ).then(function (response) {
    UI.hideLoader();
    if (response.ok) {
      response.json().then(function (json) {
        console.log(json)
        json.forEach(element => {
          let cardToSend = "";

          /*********** GET ALL ESTABLISHMENTS WITH SWITCH BUTTON (Active and inactive) *********/
          if (element.hasOwnProperty('enabled')) {
            /*------------------------------------------------------------
                Get elements with switch ACTIVE button [ Dinny + Moro ]
            -------------------------------------------------------------*/
            if(element.enabled){
              cardToSend = cardTemplate[element.origin.toLowerCase() + "Switch"] // Active template

              containerAll.insertAdjacentHTML('beforeend', cardToSend
                .replace(/__SHOW_SYNC__/g, element.has_menus ? 'syncMenu(__ID__);' : 'UI.message({ title: \'\', content: \'Agrega al menos un menú y configura el horario de tu negocio para que puedas visualizarlo en la aplicación de Dinny.\', accept: \'Aceptar\' });')
                .replace(/___NAME___/ig, element.name == '' ? element.slug : element.name)
                .replace(/__URL__/ig, encodeURIComponent(element.name == '' ? element.slug : element.name))
                .replace(/__NAME__/ig, element.name == '' ? element.slug : element.name.replace(/'/ig, "\\'"))
                .replace(/__ID__/g, element.id.toString().replace('pim-', ''))
                .replace(/__ID_DINNY__/g, element.id)
                .replace(/__DATAID__/g, element.id)
                .replace(/__IMAGE__/g, (element.picture_url == undefined || element.picture_url == null || element.picture_url == '' || element.picture_url == 'no-logo') ? 'src/img/store/profile_default.png' : `${element.picture_url}?v=${new Date().getTime()}`)
                .replace(/__COMPLETE_INFO__/g,
                  `<span class="action action-edit ml-1" onclick="showCompleteInfo('${element.id}');">
                          <i class="icon-settings"></i>
                        </span>`
                )
              );
            }
            /*--------------------------------------------------------------
                  Get elements with switch INACTIVE button [ Dinny + Moro ]
            ---------------------------------------------------------------*/
            else{
              cardToSend = cardTemplate[element.origin.toLowerCase() + "SwitchPaused"]; // Inactive template

              containerInactive.insertAdjacentHTML('beforeend', cardToSend
                .replace(/__SHOW_SYNC__/g, element.has_menus ? 'syncMenu(__ID__);' : 'UI.message({ title: \'\', content: \'Agrega al menos un menú y configura el horario de tu negocio para que puedas visualizarlo en la aplicación de Dinny.\', accept: \'Aceptar\' });')
                .replace(/___NAME___/ig, element.name == '' ? element.slug : element.name)
                .replace(/__URL__/ig, encodeURIComponent(element.name == '' ? element.slug : element.name))
                .replace(/__NAME__/ig, element.name == '' ? element.slug : element.name.replace(/'/ig, "\\'"))
                .replace(/__ID__/g, element.id.toString().replace('pim-', ''))
                .replace(/__ID_DINNY__/g, element.id)
                .replace(/__DATAID__/g, element.id)
                .replace(/__IMAGE__/g, (element.picture_url == undefined || element.picture_url == null || element.picture_url == '' || element.picture_url == 'no-logo') ? 'src/img/store/profile_default.png' : `${element.picture_url}?v=${new Date().getTime()}`)
                .replace(/__COMPLETE_INFO__/g,
                  `<span class="action action-edit ml-1" onclick="showCompleteInfo('${element.id}');">
                          <i class="icon-settings"></i>
                        </span>`
                )
              );
            }
          }
          /*********** GET ALL ESTABLISHMENTS WITHOUT SWITCH BUTTON (All inactive) *************/
          else {
            if (element.origin != "PIM") { //Preventing to show switch button in PIM establishments, that button can only be shown in sincronized with Dinny establishments
              cardToSend = cardTemplate[element.origin.toLowerCase() + "SwitchPaused"]; // Inactive template

              containerInactive.insertAdjacentHTML('beforeend', cardToSend
                .replace(/__SHOW_SYNC__/g, element.has_menus ? 'syncMenu(__ID__);' : 'UI.message({ title: \'\', content: \'Agrega al menos un menú y configura el horario de tu negocio para que puedas visualizarlo en la aplicación de Dinny.\', accept: \'Aceptar\' });')
                .replace(/___NAME___/ig, element.name == '' ? element.slug : element.name)
                .replace(/__URL__/ig, encodeURIComponent(element.name == '' ? element.slug : element.name))
                .replace(/__NAME__/ig, element.name == '' ? element.slug : element.name.replace(/'/ig, "\\'"))
                .replace(/__ID__/g, element.id.toString().replace('pim-', ''))
                .replace(/__ID_DINNY__/g, element.id)
                .replace(/__DATAID__/g, element.id)
                .replace(/__IMAGE__/g, (element.picture_url == undefined || element.picture_url == null || element.picture_url == '' || element.picture_url == 'no-logo') ? 'src/img/store/profile_default.png' : `${element.picture_url}?v=${new Date().getTime()}`)
                .replace(/__COMPLETE_INFO__/g,
                  `<span class="action action-edit ml-1" onclick="showCompleteInfo('${element.id}');">
                        <i class="icon-settings"></i>
                      </span>`
                )
              );
            }
          }

          /*********** GET MORO ESTABLISHMENTS *************************************************/
          if (element.id.toString().includes('zetus')) {
            /*--------------------------------------------------------------------
                Only Moro establisments with switch active button
            ---------------------------------------------------------------------*/
            if (element.hasOwnProperty('enabled')) {
              if (element.enabled){
                cardToSend = cardTemplate[element.origin.toLowerCase() + "Switch"]

                containerMoro.insertAdjacentHTML('beforeend', cardToSend
                  .replace(/__SHOW_SYNC__/g, element.has_menus ? 'syncMenu(__ID__);' : 'UI.message({ title: \'\', content: \'Agrega al menos un menú y configura el horario de tu negocio para que puedas visualizarlo en la aplicación de Dinny.\', accept: \'Aceptar\' });')
                  .replace(/___NAME___/ig, element.name == '' ? element.slug : element.name)
                  .replace(/__URL__/ig, encodeURIComponent(element.name == '' ? element.slug : element.name))
                  .replace(/__NAME__/ig, element.name == '' ? element.slug : element.name.replace(/'/ig, "\\'"))
                  .replace(/__ID__/g, element.id.toString().replace('pim-', ''))
                  .replace(/__ID_DINNY__/g, element.id)
                  .replace(/__DATAID__/g, element.id)
                  .replace(/__IMAGE__/g, (element.picture_url == undefined || element.picture_url == null || element.picture_url == '' || element.picture_url == 'no-logo') ? 'src/img/store/profile_default.png' : `${element.picture_url}?v=${new Date().getTime()}`)
                  .replace(/__COMPLETE_INFO__/g,
                    `<span class="action action-edit ml-1" onclick="showCompleteInfo('${element.id}');">
                            <i class="icon-settings"></i>
                          </span>`
                  )
                );
              }
            }
          }
          /*********** GET DINNY ESTABLISHMENTS ***********************************************/
          else{
            /*--------------------------------------------------------------------
                Only Dinny establisments with switch ACTIVE button
            ---------------------------------------------------------------------*/
            if (element.hasOwnProperty('enabled')) {
              if(element.enabled){
                cardToSend = cardTemplate[element.origin.toLowerCase() + "Switch"] // Active Template

                containerDinny.insertAdjacentHTML('beforeend', cardToSend
                  .replace(/__SHOW_SYNC__/g, element.has_menus ? 'syncMenu(__ID__);' : 'UI.message({ title: \'\', content: \'Agrega al menos un menú y configura el horario de tu negocio para que puedas visualizarlo en la aplicación de Dinny.\', accept: \'Aceptar\' });')
                  .replace(/___NAME___/ig, element.name == '' ? element.slug : element.name)
                  .replace(/__URL__/ig, encodeURIComponent(element.name == '' ? element.slug : element.name))
                  .replace(/__NAME__/ig, element.name == '' ? element.slug : element.name.replace(/'/ig, "\\'"))
                  .replace(/__ID__/g, element.id.toString().replace('pim-', ''))
                  .replace(/__ID_DINNY__/g, element.id)
                  .replace(/__DATAID__/g, element.id)
                  .replace(/__IMAGE__/g, (element.picture_url == undefined || element.picture_url == null || element.picture_url == '' || element.picture_url == 'no-logo') ? 'src/img/store/profile_default.png' : `${element.picture_url}?v=${new Date().getTime()}`)
                  .replace(/__COMPLETE_INFO__/g,
                    `<span class="action action-edit ml-1" onclick="showCompleteInfo('${element.id}');">
                            <i class="icon-settings"></i>
                          </span>`
                  )
                );
              }
            }
            /*------------------------------------------------------------------
                Dinny establishments without switch INACTIVE button ( all disabled )
            --------------------------------------------------------------------*/
            else {
              cardToSend = cardTemplate[element.origin.toLowerCase()] // Inactive Template

              containerInactive.insertAdjacentHTML('beforeend', cardToSend
                .replace(/__SHOW_SYNC__/g, element.has_menus ? 'syncMenu(__ID__);' : 'UI.message({ title: \'\', content: \'Agrega al menos un menú y configura el horario de tu negocio para que puedas visualizarlo en la aplicación de Dinny.\', accept: \'Aceptar\' });')
                .replace(/___NAME___/ig, element.name == '' ? element.slug : element.name)
                .replace(/__URL__/ig, encodeURIComponent(element.name == '' ? element.slug : element.name))
                .replace(/__NAME__/ig, element.name == '' ? element.slug : element.name.replace(/'/ig, "\\'"))
                .replace(/__ID__/g, element.id.toString().replace('pim-', ''))
                .replace(/__ID_DINNY__/g, element.id)
                .replace(/__DATAID__/g, element.id)
                .replace(/__IMAGE__/g, (element.picture_url == undefined || element.picture_url == null || element.picture_url == '' || element.picture_url == 'no-logo') ? 'src/img/store/profile_default.png' : `${element.picture_url}?v=${new Date().getTime()}`)
                .replace(/__COMPLETE_INFO__/g,
                  `<span class="action action-edit ml-1" onclick="showCompleteInfo('${element.id}');">
                          <i class="icon-settings"></i>
                        </span>`
                )
              );
            }
          }
        });
      })
    } else {
      response.json().then(function (content) {
        if (403 == response.status) {
          UI.showLoader();
          window.location.href = 'login.html'
        } else {
          UI.message({ title: 'Error', content: 'Ocurrió un error al cargar el establecimiento.', accept: 'Aceptar' });
        }
      })
    }
  })
    .catch(function (e) {
      UI.message({ title: 'Error', content: 'Ocurrió un error al cargar el establecimiento.', accept: 'Aceptar' });
      UI.hideLoader();
    })
}

  addStoreButton.addEventListener('click', (e) => {
    e.preventDefault();

    document.getElementById('establishment_name').value = "";
    document.querySelector('.menu-product-img').innerHTML = "<img src='src/img/store/menu/product_default.png' />";
    document.getElementById('store-logo').value = "";
    document.querySelector('.modal-body-title').innerText = "Registrar establecimiento";
    saveModal.innerText = "Guardar";
    delete saveModal.dataset.id;

    modalMenu.style.display = 'block';
  });

saveModal.addEventListener('click', (e) => {
    e.preventDefault();

    let isAdd = !saveModal.dataset.id;
    let name = document.getElementById('establishment_name').value,
        establishment_image = document.getElementById('store-logo').files[0];

    if(name.length == 0)
    {
        UI.message({title: 'Faltan datos', content: 'Escriba el nombre del establecimiento.', accept: 'Aceptar'});
        return;
    }

    if(name.length < 2)
    {
        UI.message({title: 'Datos incompletos', content: 'El nombre del establecimiento es muy corto, escriba al menos dos caracteres.', accept: 'Aceptar'});
        return;
    }

    if(!/^[\w.\-@/&+¿?¡!*' áéíóúüñÁÉÍÓÚÜÑ]+$/.test(name)){
      UI.message({title: 'Datos incorrectos', content: 'El nombre del establecimiento contiene caracteres no permitidos.', accept: 'Aceptar'});
      return;
    }

    if(isAdd && (establishment_image == null || establishment_image === "undefined"))
    {
        UI.message({title: 'Faltan datos', content: 'Seleccione una imagen para el establecimiento.', accept: 'Aceptar'});
        return;
    }

    let objEstablishment = {
      id: isAdd ? 0 : parseInt(saveModal.dataset.id),
      name,
      picture_url: url_picture,
      is_active: true
    };

    UI.showLoader();
    if(url_picture == null){
      //upload picture
      let objReader = new FileReader();
      objReader.readAsDataURL(establishment_image);
      objReader.onload = function(e){
          let base64string = e.target.result;
          base64string = base64string.replace(base64string.substring(0, base64string.indexOf(',') + 1), "");

          fetch(
              BASE_URLS.Standalone.api + '/picture',
              {
              method: 'POST',
              body: JSON.stringify({
                  key: 'image/store/' + new Date().toISOString().replace(/:/g, '').replace(/-/g, '').replace(/\./g, '') + '.' + establishment_image.name.split('.').pop(),
                  content: base64string,
                  contentType: establishment_image.type
              }),
              credentials: 'include'
          }).then(function(response){
              if(response.ok){
                  response.json().then(function(picture_url){
                      url_picture = picture_url;
                      objEstablishment.picture_url = url_picture;

                      if(isAdd)
                        addStore(objEstablishment);
                      else
                        updateStore(objEstablishment);
                  });
              }else{
                  response.json().then(function(content){
                      if(403 == response.status){
                          window.location.href = 'login.html'
                      }else{
                          UI.hideLoader();
                          UI.message({title: 'Ocurrió un error', content: 'Ocurrió un error al guardar la imagen.', accept: 'Aceptar'});
                      }
                  });
              }
          }).catch(function(e){
              UI.hideLoader();
              UI.message({title: 'Ocurrió un error', content: 'Ocurrió un error al guardar la imagen.', accept: 'Aceptar'});
              console.log(e);
          });
      };
    }
    else{
      if(isAdd)
        addStore(objEstablishment);
      else
        updateStore(objEstablishment);
    }
});

cancelModal.addEventListener('click', () => {
  modalMenu.style.display = 'none';
});

window.addEventListener ('click', (e) => {
    if (e.target == modalContainer) {
      modalMenu.style.display = 'none';
    }
});

function addStore(data){

    fetch(
        BASE_URLS.Standalone.api + '/establishments',
        {
          method: 'POST',
          body: JSON.stringify(data),
          credentials: 'include'
    }).then(function(response){
        UI.hideLoader();
        if(response.ok){
          modalMenu.style.display = 'none';
          document.getElementById('establishment_name').value = "";
          document.getElementById('store-logo').value = "";
          loadStores();
        }
        else{
          response.json().then(function(content){
              if(403 == response.status){
                  window.location.href = 'login.html'
              }else{
                  UI.hideLoader();
                  UI.message({title: 'Ocurrió un error', content: 'No se pudo dar de alta el establecimiento.', accept: 'Aceptar'});
              }
          });
        }
    }).catch(function(error){
      UI.message({title: 'Ocurrió un error', content: 'Ocurrió un error al dar de alta el establecimiento.', accept: 'Aceptar'});
        console.log(error);
    })
}

function updateStore(data){
  fetch(
      BASE_URLS.Standalone.api + '/establishments',
      {
        method: 'PUT',
        mode: 'cors',
        body: JSON.stringify(data),
        credentials: 'include'
  }).then(function(response){
      UI.hideLoader();
      if(response.ok){
        modalMenu.style.display = 'none';
        document.getElementById('establishment_name').value = "";
        document.getElementById('store-logo').value = "";
        loadStores();
      }
      else{
        response.json().then(function(content){
            if(403 == response.status){
                window.location.href = 'login.html'
            }else{
                UI.hideLoader();
                UI.message({title: 'Ocurrió un error', content: 'No se pudo actualizar el establecimiento.', accept: 'Aceptar'});
            }
        });
      }

  }).catch(function(error){
    UI.message({title: 'Ocurrió un error', content: 'Ocurrió un error al actualizar el establecimiento.', accept: 'Aceptar'});
      console.log(error);
  })
}

function updateEstablishment(id, element){
  document.getElementById('establishment_name').value = element.dataset.name;

  let outPut = document.querySelector('.menu-product-img');
  outPut.innerHTML = "";
  let outPutImage = document.createElement("img");
  outPutImage.src = element.dataset.image;
  outPut.appendChild(outPutImage);
  url_picture = element.dataset.image;

  saveModal.innerText = "Guardar";
  document.querySelector('.modal-body-title').innerText = "Editar establecimiento";
  saveModal.dataset.id = id;

  modalMenu.style.display = 'block';
}


function editMenu(name, id, image){
  sessionStorage.removeItem("selected_store");
  sessionStorage.setItem("selected_store", JSON.stringify({ name, id, image }));

  location.href = "menus.html";
}


function showCompleteInfo(idStore, element){
  localStorage.setItem('currentStore', idStore);
  location.href = 'profile.html?store_name=' + encodeURIComponent(element.dataset.name) + '&store_image=' + encodeURIComponent(element.dataset.image) + '&store_id=' + idStore;
}
function deleteEstablishmentFromDinny(id, element){
  UI.confirm(
    {
      title: '<b>Borrar establecimiento</b>',
      content: `<p>¿Deseas eliminar el establecimiento "<b>${element.dataset.name}</b>"?</p>
                <p>Toma en cuenta que también se eliminarán los menús, productos y modificadores que estén asociados al establecimiento y no será visible en Dinny.</p>`,
      cancel: 'Cancelar',
      accept: 'Aceptar'
    },
    function(resp){
      if(resp){
        UI.showLoader();
        fetch(
          BASE_URLS.Dinny.api + '/stores/' + id + '/?req_id=' + new Date().getTime(),
          {
              method: 'DELETE',
              credentials: 'include'
          }
        ).then(function(response){
          UI.hideLoader();
            if(response.ok){
                loadStores();
            }
            else{
              response.json().then(function(content){
                  if(403 == response.status){
                    window.location.href = 'login.html'
                  }else{
                    UI.message({title: 'Ocurrió un error', content: 'No se pudo eliminar el establecimiento.', accept: 'Aceptar'});
                  }
                });
            }
        }).catch(function(e){
          UI.hideLoader();
          console.log(e)
          UI.message({title: 'Ocurrió un error', content: 'Ocurrió un error al eliminar el establecimiento.', accept: 'Aceptar'});
        })
      }
    }
  )
}

function deleteEstablishmentBoth(id, element){
  UI.confirm(
    {
      title: '<b>Borrar establecimiento</b>',
      content: `<p>¿Deseas eliminar el establecimiento "<b>${element.dataset.name}</b>"?</p>
                <p>Toma en cuenta que también se eliminarán los menús, productos y modificadores que estén asociados al establecimiento y no será visible en Dinny.</p>`,
      cancel: 'Cancelar',
      accept: 'Aceptar'
    },
    function(resp){
      if(resp){
        UI.showLoader();
        fetch(
          BASE_URLS.Dinny.api + '/stores/' + id + '/?req_id=' + new Date().getTime(),
          {
              method: 'DELETE',
              credentials: 'include'
          }
        ).then(function(response){
            if(response.ok){
              id = id.toString().match(/[0-9]+$/)
              fetch(
                BASE_URLS.Standalone.api + '/establishments/' + id + '/?req_id=' + new Date().getTime(),
                {
                    method: 'DELETE',
                    credentials: 'include'
                }
              ).then(function(response){
                UI.hideLoader();
                  if(response.ok){
                      loadStores();
                  }
                  else{
                    response.json().then(function(content){
                        if(403 == response.status){
                          window.location.href = 'login.html'
                        }else{
                          UI.message({title: 'Ocurrió un error', content: 'No se pudo eliminar el establecimiento.', accept: 'Aceptar'});
                        }
                      });
                  }
              }).catch(function(e){
                UI.hideLoader();
                console.log(e)
                UI.message({title: 'Ocurrió un error', content: 'Ocurrió un error al eliminar el establecimiento.', accept: 'Aceptar'});
              });
            }
            else{
              response.json().then(function(content){
                  if(403 == response.status){
                    window.location.href = 'login.html'
                  }else{
                    UI.message({title: 'Ocurrió un error', content: 'No se pudo eliminar el establecimiento.', accept: 'Aceptar'});
                  }
                });
            }
        }).catch(function(e){
          UI.hideLoader();
          console.log(e)
          UI.message({title: 'Ocurrió un error', content: 'Ocurrió un error al eliminar el establecimiento.', accept: 'Aceptar'});
        })
      }
    }
  )
}

function syncMenu(id){
  UI.showLoader();
  fetch(
    BASE_URLS.Standalone.api + '/menusync?req_id=' + new Date().getTime(),
    {
        method: 'POST',
        credentials: 'include',
        body: JSON.stringify({
          store_id: id
        }),
    }
  ).then(function(response){
    UI.hideLoader();
      if(response.ok){
        UI.message({title: 'Sincronización con la aplicación Dinny', content: 'Se realizó con éxito, ya puedes visualizarlo en la aplicación.', accept: 'Aceptar'}, function(){
          loadStores();
        });
      }
      else{
        response.json().then(function(content){
            if(403 == response.status){
              window.location.href = 'login.html'
            }else{
              UI.message({title: 'Ocurrió un error', content: 'No se pudo sincronizar el establecimiento.', accept: 'Aceptar'});
            }
          });
      }
  }).catch(function(e){
    UI.hideLoader();
    console.log(e)
    UI.message({title: 'Ocurrió un error', content: 'Ocurrió un error al sincronizar el establecimiento.', accept: 'Aceptar'});
  });
}

//Nav
elementsNavPanel.forEach(function(elementNav){
  elementNav.addEventListener("click", function(e){
      document.querySelector('.item-menu-establishment-active').classList.remove('item-menu-establishment-active');
      e.target.classList.add('item-menu-establishment-active');
      var dataNav = e.target.dataset.option;

      // Set to default breadcrumb clean
      document.querySelector('.breadcrumb').innerHTML = `<a class="breadcrumb-index" href="index.html">Inicio</a>`

      // Set the Breadcrumb in Header to the section selected
      if(dataNav == 0){ // Dinny Active Establishments 
        document.querySelector('.breadcrumb').insertAdjacentHTML(
          'beforeend',
          ` 
            <a class="breadcrumb breadcrumb-level" href="index.html">Establecimientos</a>
            <a class="breadcrumb breadcrumb-level breadcrumb breadcrumb-level--active" href="javascript:void(0);">Dinny activos</a>
          `
        );
      }
      else if(dataNav == 1){ // Moro Active Establishments 
        document.querySelector('.breadcrumb').insertAdjacentHTML(
          'beforeend',
          ` 
            <a class="breadcrumb breadcrumb-level" href="index.html">Establecimientos</a>
            <a class="breadcrumb breadcrumb-level breadcrumb breadcrumb-level--active" href="javascript:void(0);">Moro activos</a>
          `
        );
      }
      else if(dataNav == 2){ // All Active Establishments
        document.querySelector('.breadcrumb').insertAdjacentHTML(
          'beforeend',
          ` 
            <a class="breadcrumb breadcrumb-level" href="index.html">Establecimientos</a>
            <a class="breadcrumb breadcrumb-level breadcrumb breadcrumb-level--active" href="javascript:void(0);">Todos activos</a>
          `
        );
      }
      else if(dataNav == 3){ // All Inactive Establishments
        document.querySelector('.breadcrumb').insertAdjacentHTML(
          'beforeend',
          ` 
            <a class="breadcrumb breadcrumb-level" href="index.html">Establecimientos</a>
            <a class="breadcrumb breadcrumb-level breadcrumb breadcrumb-level--active" href="javascript:void(0);">Todos inactivos</a>
          `
        );
      }

      panels.forEach(function(panel){
          if(panel.className.includes("hide") && panel.dataset.panel == dataNav){
              panel.classList.remove('hide');
          }
          else{
              if(!panel.className.includes("hide") && panel.dataset.panel != dataNav){
                  panel.classList.add('hide');
              }
          }
      });
  });
});

function openPauseModal(e){
  confirmPauseModal.dataset.confirm = e.target.parentNode.parentNode.parentNode.dataset.id;
  modalPause.classList.remove('hide');
  modalPause.querySelector('.modal-pause-img img').src = e.target.parentNode.querySelector('img').src;

  if(e.target.parentNode.parentNode.parentNode.classList.value.includes('establishment-paused')){
    modalPause.querySelector('.modal-pause-text').textContent = "Tu establecimiento se activará, al confirmar se mostrará en la aplicación de Dinny.";
    confirmPauseModal.dataset.enabled = 0;
  }
  else{
    modalPause.querySelector('.modal-pause-text').textContent = "Tu establecimiento se pausará, al confirmar no se mostrará en la aplicación de Dinny.";
    confirmPauseModal.dataset.enabled = 1;
  }

}

function closePauseModal(){
  modalPause.classList.add('hide');
}

function pauseEstablishment(idEstablishment, enabled){
  UI.showLoader();
  fetch(
    BASE_URLS.Dinny.api + '/store/pause/' + idEstablishment,
    {
      credentials: 'include'
    }
  ).then(function(response){
    UI.hideLoader();
    if(response.ok){
      closePauseModal();
      if(enabled == 1){
        UI.message({title: 'Establecimiento pausado', content: 'El establecimiento fue pausado exitosamente.', accept: 'Aceptar'},
          function(){
            location.href='index.html';
          }
        );
      }
      else{
        UI.message({title: 'Establecimiento activado', content: 'El establecimiento se activó exitosamente.', accept: 'Aceptar'},
        function(){
          location.href='index.html';
        });
      }
    }else{
      response.json().then(function(content){
        if(403 == response.status){
          UI.showLoader();
          window.location.href = 'login.html'
        }else{
          UI.message({title: 'Error', content: 'Ocurrió un error al pausar el establecimiento.', accept: 'Aceptar'});
        }
      })
    }
  })
  .catch(function(e){
      console.log(e)
      UI.message({title: 'Error', content: 'Ocurrió un error al pausar el establecimiento.', accept: 'Aceptar'});
    UI.hideLoader();
  })
}


confirmPauseModal.addEventListener('click', function(){
  pauseEstablishment(confirmPauseModal.dataset.confirm, confirmPauseModal.dataset.enabled);
});

cancelPauseModal.addEventListener('click', closePauseModal);
const sectionContainer = document.querySelectorAll('.sidebar-menu-container-section'),
      sectionTab = document.querySelectorAll('.sidebar-menu-container-section-tab'),
      tabOption = document.querySelectorAll('.sidebar-menu-container-options-name');

if(localStorage.getItem('currentStore') != undefined && localStorage.getItem('currentStore').toLowerCase().indexOf('zetus') != -1){
  if(document.getElementById('ul-assign-images'))
  document.getElementById('ul-assign-images').style = "display:block"
}

if((localStorage.getItem('user_type') || "") == "1"){
  if(document.getElementById('ul-associations'))
    document.getElementById('ul-associations').style.display = "block";
  if(document.getElementById('ul-ad-banners'))
    document.getElementById('ul-ad-banners').style.display = "block";
  if(document.getElementById('ul-users'))
    document.getElementById('ul-users').style.display = "block";
}

// Toggle tabs
function toggleAcorddion() {
  let thisItem = this;

  sectionTab.forEach(item => {
    if (thisItem == item) {
      thisItem.classList.toggle('sidebar-menu-container-section-tab--active');
      return;
    }
    item.classList.remove('sidebar-menu-container-section-tab--active');
  });
}

sectionTab.forEach(element => element.addEventListener('click', toggleAcorddion));


// Toggle options
function toggleOption() {
  let thisItem = this;

  tabOption.forEach(item => {
    if (thisItem == item) {
      thisItem.classList.add('sidebar-menu-container-options-name--active');
      return;
    }
    item.classList.remove('sidebar-menu-container-options-name--active');
  });
}

tabOption.forEach(element => element.addEventListener('click', toggleOption));

// Logout

function logoutSliderbar(){
  localStorage.setItem('session', "------");
  sessionStorage.removeItem('context');
  window.location.href = "login.html";
}
